import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Home } from "./components/home/home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RealEstateReInvest } from "./components/re_reinvest/reinvest";

const darkTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/new", element: <RealEstateReInvest /> },
]);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
