import { Information } from "../components/information/InformationInput";
import { calculateStock } from "./calculate_stock";
import { getInterestAtYear, getRepaymentAtYear } from "./annuity_calculator";
import { calculateREInvest } from "./calculate_re_invest";

export function calculate(information: Information): Yearly[] {
  const rates = calculateYearlyDownpayments(information);
  calculateStock(information, rates);
  calculateREInvest(information, rates);
  return rates;
}

export function calculateYearlyDownpayments(inf: Information): Yearly[] {
  const yearly: Yearly[] = [
    {
      year: 0,
      interest: 0,
      repayment: 0,
      rent: 0,
      rest: inf.calculatedInformation.loan,
      taxes: 0,
      cashFlow: 0,
      cashFlowAfterTaxes: 0,
      propertyValue: inf.calculatedInformation.initialPropertyValue,
      totalAssets:
        inf.calculatedInformation.initialPropertyValue -
        inf.calculatedInformation.loan,
      returnOnEquity: 0,
      investmentInterest: 0,
      investmentInterestRate: 0,
    },
    ...calculateDownpaymentRec(
      1,
      inf.calculatedInformation.loan,
      inf.calculatedInformation.initialPropertyValue,
      inf
    ),
  ];
  return yearly;
}

export type Yearly = {
  year: number;
  interest: number;
  repayment: number;
  rent: number;
  rest: number;
  taxes: number;
  cashFlow: number;
  cashFlowAfterTaxes: number;
  propertyValue: number;
  totalAssets: number;
  returnOnEquity: number;
  // These numbers show the yearly interest that you gain from your investment
  investmentInterest?: number;
  investmentInterestRate?: number;

  // This is the total amount of money invested in stocks
  stockTotalInvest?: number;
  stockSaving?: number;
  stockInterest?: number;
  stockInterestRate?: number;
  stockPortfolio?: number;
  stockPortfolioAfterTaxes?: number;
};

// https://www.verivox.de/geldanlage/themen/zinseszins/
function getValueAtYear(base: number, year: number, interest: number): number {
  return base * Math.pow(1 + interest / 100, year);
}

function calculateDownpaymentRec(
  year: number,
  overflow: number,
  propertyValue: number,
  inf: Information
): Yearly[] {
  const interest = getInterestAtYear(
    year,
    inf.calculatedInformation.loan,
    inf.financialInformation.loan.interestRate / 100,
    inf.financialInformation.loan.creditTerm
  );
  const rent = getValueAtYear(
    inf.objectInformation.yearlyRent,
    year - 1, // The first year should be the same as the buying year
    inf.financialInformation.rentIncreaseRate
  );
  const { reserves } = inf.calculatedInformation;

  const repayment = getRepaymentAtYear(
    year,
    inf.calculatedInformation.loan,
    inf.financialInformation.loan.interestRate / 100,
    inf.financialInformation.loan.creditTerm
  );
  const rest = overflow - repayment;
  const deprecationTaxDeduction = inf.objectInformation.buyingPrice * 0.02;
  // TODO: add potential costs for workers
  const taxes =
    ((rent - deprecationTaxDeduction - interest) / 100.0) *
    inf.personalInformation.taxRate;

  const cashFlow = rent - interest - repayment - reserves;
  const cashFlowAfterTaxes = cashFlow - taxes;

  const value =
    (propertyValue / 100) * (100 + inf.financialInformation.inflation);

  // This is how much your investment is worth right now
  const totalAssets = value - rest + cashFlowAfterTaxes;

  if (rest <= 1) {
    return [
      {
        year,
        interest,
        repayment,
        rent,
        rest,
        cashFlow,
        taxes,
        cashFlowAfterTaxes,
        propertyValue: value,
        totalAssets,
        returnOnEquity:
          (100 / inf.personalInformation.equity) * totalAssets - 100,
      },
    ];
  }

  return [
    {
      year,
      interest,
      repayment,
      rent,
      rest,
      cashFlow,
      taxes,
      cashFlowAfterTaxes,
      propertyValue: value,
      totalAssets,
      returnOnEquity:
        (100 / inf.personalInformation.equity) * totalAssets - 100,
    },
    ...calculateDownpaymentRec(year + 1, rest, value, inf),
  ];
}
