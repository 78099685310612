import { useState } from "react";
// import "./App.css";
import styles from "./home.module.css";
import { calculate, Yearly } from "../../calculator/calculator";
import { InformationInput, Information } from "../information/InformationInput";
import { DefaultInformation } from "../information/DefaultInformation";
import { load, persist } from "../../local_storage";

import { LoanEvaluation } from "../load_evaluation/load_evaluation";
import { GeneralInformation } from "../general_information/GeneralInformation";
import { Tab } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext, TabList } from "@mui/lab";
import { StockEvaluation } from "../stock/stock_evaluation";
import { GeneralComparison } from "../general_comparison/general_comparison";
import { REInvestStrat } from "../re_invest_strategy";

const startInf = load() ?? DefaultInformation;
const startYearly = calculate(startInf);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export function Home() {
  const [inf, setInf] = useState<Information>(startInf);
  const [yearly, setYearly] = useState<Yearly[]>(startYearly);
  const setInformation = (inf: Information): void => {
    const yrl = calculate(inf);

    persist(inf);
    setYearly(yrl);
    setInf(inf);
  };

  const [value, setValue] = useState("1");
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className={styles.app}>
        <div className={styles.header}>
          <div></div>
          <InformationInput
            information={startInf}
            setInfomrationHook={setInformation}
          />
        </div>
        <div className={styles.body}>
          <div></div>
          <div className={styles.bodyContent}>
            <TabContext value={value}>
              <TabList onChange={handleChange}>
                <Tab label="General Information" value="0" {...a11yProps(0)} />
                <Tab label="Comparison" value="1" {...a11yProps(0)} />
                <Tab label="Real Estate" value="2" {...a11yProps(0)} />
                <Tab label="Stock" value="3" {...a11yProps(0)} />
                <Tab label="RE Invest" value="4" {...a11yProps(0)} />
              </TabList>
              <TabPanel value="0">
                <GeneralInformation information={inf} />
              </TabPanel>
              <TabPanel value="1">
                <GeneralComparison information={inf} yearly={yearly} />
              </TabPanel>
              <TabPanel value="2">
                <LoanEvaluation information={inf} yearly={yearly} />
              </TabPanel>
              <TabPanel value="3">
                <StockEvaluation information={inf} yearly={yearly} />
              </TabPanel>
              <TabPanel value="4">
                <REInvestStrat />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </div>
  );
}
