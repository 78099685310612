import { Information } from "./InformationInput";
import { LoanType } from "./LoanOptions";

export const DefaultInformation: Information = {
  objectInformation: {
    sizeInSquareMeters: 80,
    buyingPrice: 180_000,
    additionalCost: 10_000,
    valueGrowthThroughWork: 10_000,
    yearlyRent: 9_000,
    yearOfConstruction: 1992,

    brokerageFee: 3.0,
    notary: 2.0,
    landTransferTax: 6.0,
  },
  financialInformation: {
    loan: {
      type: LoanType.Annuity,
      interestRate: 4.0,
      creditTerm: 30,
    },
    // https://immocashflow.com/update-immocashflow-immobilien-kalkulationstool/
    lossOfRentReserves: 3.0,
    // https://www.drklein.de/instandhaltungsruecklage.html
    maintenanceReserves: 2.0,
    inflation: 2.0,
    rentIncreaseRate: 2.0,
  },
  personalInformation: {
    taxRate: 42.0,
    equity: 54_000,
  },
  stockInformation: {
    interestRateMin: 5,
    interestRateMax: 9,
  },
  calculatedInformation: {
    totalCost: 0,
    loan: 0,
    reserves: 0,
    additionalCost: 0,
    grossReturn: 0,
    returnFactor: 0,
    equityRatio: 0,
    annuity: 0,
    annuityRatio: 0,
    initialPropertyValue: 0,
  },
};
