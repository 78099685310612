import { Information } from "../components/information/InformationInput";
import { Yearly } from "./calculator";

export function calculateREInvest(inf: Information, yearly: Yearly[]) {
  calculateREInvestmentInterest(inf, yearly);
}

// This function calcultest the yearly gain from the real estate investment
function calculateREInvestmentInterest(_inf: Information, yearly: Yearly[]) {
  for (let i = 2; i < yearly.length; i++) {
    const lastYear = yearly[i - 1];
    const thisYear = yearly[i];

    yearly[i].investmentInterest = thisYear.totalAssets - lastYear.totalAssets;
    yearly[i].investmentInterestRate =
      (100 / lastYear.totalAssets) * thisYear.totalAssets - 100;
  }
}
