import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Yearly } from "../../calculator/calculator";
import { Information } from "../information/InformationInput";
import { GeneralComparisonCharts } from "./charts";
import { GeneralComparisonDetails } from "./details";

export type GeneralComparisonProps = {
  information: Information;
  yearly: Yearly[];
};

export function GeneralComparison(props: GeneralComparisonProps) {
  return (
    <div>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Charts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GeneralComparisonCharts
            information={props.information}
            yearly={props.yearly}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Detailed information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GeneralComparisonDetails
            information={props.information}
            yearly={props.yearly}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
