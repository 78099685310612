import { Number } from "../common/number";
import { NumberFormatValues } from "react-number-format";
import { useState } from "react";
import styles from "./InformationInput.module.css";

export enum LoanType {
  Annuity = "Annuity",
}
export type LoanInformation = {
  type: LoanType;
  creditTerm: number;
  interestRate: number;
};

export type SetLoanInformationHook = (information: LoanInformation) => void;
export type LoanInformationProps = {
  information: LoanInformation;
  setInfomrationHook: SetLoanInformationHook;
};

export function LoanInformationComponent(props: LoanInformationProps) {
  const [information, setInformation] = useState<LoanInformation>(
    props.information
  );

  const setInterestRate = (value: NumberFormatValues) => {
    const interestRate = parseFloat(value.value);
    setInformation({ ...information, interestRate });
    props.setInfomrationHook({ ...information, interestRate });
  };

  const setCreditTerm = (value: NumberFormatValues) => {
    const creditTerm = parseFloat(value.value);
    setInformation({ ...information, creditTerm });
    props.setInfomrationHook({ ...information, creditTerm });
  };

  return (
    <div>
      <div className={styles.formRow}>
        <Number
          value={information.interestRate}
          onChange={setInterestRate}
          label="Interest Rate"
          prefix="% "
        />
      </div>
      <div className={styles.formRow}>
        <Number
          value={information.creditTerm}
          onChange={setCreditTerm}
          label="Credit Term"
          prefix="Years "
        />
      </div>
    </div>
  );
}
