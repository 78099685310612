import React from "react";
import { NumberFormatValues } from "react-number-format";
import { fillNumbers } from "../../calculator/fill_numbers";
import { Number } from "../common/number";
import styles from "./InformationInput.module.css";
import { LoanInformation, LoanInformationComponent } from "./LoanOptions";
import { Slider } from "@mui/material";

export type Information = {
  objectInformation: ObjectInformation;
  financialInformation: FinancialInformation;
  personalInformation: PersonalInformation;
  stockInformation: StockInformation;
  calculatedInformation: CalculatedInformation;
};

export type ObjectInformation = {
  sizeInSquareMeters: number;
  buyingPrice: number;
  // Additional costs like colors, kitchen, etc. Money has to be invested, but will not automatically increase the value of the property
  additionalCost: number;
  // This field gives you the possibility to increase the property value by a certain amount.
  // It can include things bought from additional costs or even manual work
  valueGrowthThroughWork: number;
  yearlyRent: number;
  yearOfConstruction: number;

  brokerageFee: number;
  notary: number;
  landTransferTax: number;
};

export type FinancialInformation = {
  loan: LoanInformation;
  lossOfRentReserves: number;
  maintenanceReserves: number;
  inflation: number;
  rentIncreaseRate: number;
};

export type PersonalInformation = {
  taxRate: number;
  equity: number;
};

export type StockInformation = {
  interestRateMin: number;
  interestRateMax: number;
};

export type CalculatedInformation = {
  totalCost: number;
  loan: number;
  reserves: number;
  additionalCost: number;
  equityRatio: number;
  annuity: number;
  annuityRatio: number;
  initialPropertyValue: number;

  grossReturn: number;
  returnFactor: number;
};

export type SetInformationHook = (inf: Information) => void;
export type InformationProps = {
  information: Information;
  setInfomrationHook: SetInformationHook;
};

type InformationSate = {
  information: Information;
};

export class InformationInput extends React.Component<
  InformationProps,
  InformationSate
> {
  constructor(props: InformationProps) {
    super(props);
    this.state = { information: props.information };
    this.props.setInfomrationHook(this.state.information);
  }

  setBuyingPrice(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        buyingPrice: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setAdditionalCost(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        additionalCost: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setOBJValueGrowthThroughWork(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        valueGrowthThroughWork: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setSizeInSM(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        sizeInSquareMeters: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setOYearofConstruction(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        yearOfConstruction: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setYearlyRent(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        yearlyRent: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setBrokerageFee(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        brokerageFee: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setNotary(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        notary: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setLandTransferTax(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      objectInformation: {
        ...this.state.information.objectInformation,
        landTransferTax: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setFLoan(loanInfo: LoanInformation): void {
    const information = {
      ...this.state.information,
      financialInformation: {
        ...this.state.information.financialInformation,
        loan: loanInfo,
      },
    };
    this.updateInformation(information);
  }

  setFLossOfRentReserves(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      financialInformation: {
        ...this.state.information.financialInformation,
        lossOfRentReserves: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setFMaintenanceReserves(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      financialInformation: {
        ...this.state.information.financialInformation,
        maintenanceReserves: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setFTaxRate(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      personalInformation: {
        ...this.state.information.personalInformation,
        taxRate: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setFInflationRate(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      financialInformation: {
        ...this.state.information.financialInformation,
        inflation: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setFRentIncreaseRate(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      financialInformation: {
        ...this.state.information.financialInformation,
        rentIncreaseRate: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setPEquity(values: NumberFormatValues): void {
    const information = {
      ...this.state.information,
      personalInformation: {
        ...this.state.information.personalInformation,
        equity: values.floatValue!,
      },
    };
    this.updateInformation(information);
  }

  setSInterestRate(_event: Event, newValue: number | number[]): void {
    const newVal = newValue as number[];
    const information = {
      ...this.state.information,
      stockInformation: {
        ...this.state.information.stockInformation,
        interestRateMin: newVal[0],
        interestRateMax: newVal[1],
        interestRate: (newVal[0] + newVal[1]) / 2,
      },
    };
    this.updateInformation(information);
  }

  updateInformation(inf: Information) {
    const information = fillNumbers(inf);
    this.props.setInfomrationHook(information);
    this.setState((cur) => ({ ...cur, information }));
  }

  render() {
    return (
      <div className={styles.informationWrapper}>
        <h1 className={styles.header}>Information</h1>
        <div className={styles.informationInput}>
          <div className={styles.form}>
            <h2 className={styles.header}>Object Information</h2>
            <div className={styles.formRow}>
              <Number
                value={this.state.information.objectInformation.buyingPrice}
                onChange={this.setBuyingPrice.bind(this)}
                label="Buying price"
                prefix="€ "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={this.state.information.objectInformation.additionalCost}
                onChange={this.setAdditionalCost.bind(this)}
                label="Additional Cost"
                prefix="€ "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={
                  this.state.information.objectInformation
                    .valueGrowthThroughWork
                }
                onChange={this.setOBJValueGrowthThroughWork.bind(this)}
                label="Value Growth Through Work"
                prefix="€ "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={
                  this.state.information.objectInformation.sizeInSquareMeters
                }
                onChange={this.setSizeInSM.bind(this)}
                label="Size in QM2"
                prefix="m2 "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={this.state.information.objectInformation.yearlyRent}
                onChange={this.setYearlyRent.bind(this)}
                label="Yearly Rent"
                prefix="€ "
              />
            </div>
            <hr />
            <div className={styles.formRow}>
              <Number
                value={this.state.information.objectInformation.brokerageFee}
                onChange={this.setBrokerageFee.bind(this)}
                label="Brokerage Fee"
                prefix="% "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={this.state.information.objectInformation.notary}
                onChange={this.setNotary.bind(this)}
                label="Notary"
                prefix="% "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={this.state.information.objectInformation.landTransferTax}
                onChange={this.setLandTransferTax.bind(this)}
                label="Land Transfer Tax"
                prefix="% "
              />
            </div>
          </div>
          <div className={styles.form}>
            <h2 className={styles.header}>Financial Information</h2>
            <div className={styles.formRow}>
              <Number
                value={
                  this.state.information.financialInformation.lossOfRentReserves
                }
                onChange={this.setFLossOfRentReserves.bind(this)}
                label="Loss Of Rent Reserves"
                prefix="% "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={
                  this.state.information.financialInformation
                    .maintenanceReserves
                }
                onChange={this.setFMaintenanceReserves.bind(this)}
                label="Maintenance Reserves / qm2"
                prefix="€ "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={this.state.information.financialInformation.inflation}
                onChange={this.setFInflationRate.bind(this)}
                label="Inflation Rate"
                prefix="% "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={
                  this.state.information.financialInformation.rentIncreaseRate
                }
                onChange={this.setFRentIncreaseRate.bind(this)}
                label="Rent Increase"
                prefix="% "
              />
            </div>
          </div>
          <div className={styles.form}>
            <h2 className={styles.header}>Loan</h2>
            <LoanInformationComponent
              information={this.state.information.financialInformation.loan}
              setInfomrationHook={this.setFLoan.bind(this)}
            />
          </div>
          <div className={styles.form}>
            <h2 className={styles.header}>Personal</h2>
            <div className={styles.formRow}>
              <Number
                value={this.state.information.personalInformation.taxRate}
                onChange={this.setFTaxRate.bind(this)}
                label="Tax Rate"
                prefix="% "
              />
            </div>
            <div className={styles.formRow}>
              <Number
                value={this.state.information.personalInformation.equity}
                onChange={this.setPEquity.bind(this)}
                label="Equity"
                prefix="€ "
              />
            </div>
          </div>
          <div className={styles.form}>
            <h2 className={styles.header}>Stock</h2>
            <div className={styles.formRow}>
              <Slider
                getAriaLabel={() => "Temperature range"}
                min={0}
                max={15}
                step={1}
                value={[
                  this.state.information.stockInformation.interestRateMin,
                  this.state.information.stockInformation.interestRateMax,
                ]}
                onChange={this.setSInterestRate.bind(this)}
                valueLabelDisplay="on"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
