import { NumericFormat } from "react-number-format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Information } from "../information/InformationInput";
import styles from "./GeneralInformation.module.css";

export type GeneralInformationProps = {
  information: Information;
};

export function GeneralInformation(props: GeneralInformationProps) {
  return (
    <div className={styles.wrapper}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow hover={false}>
              <TableCell align="right">Equity Ratio: </TableCell>
              <TableCell align="left">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={props.information.calculatedInformation.equityRatio}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" %"
                />
              </TableCell>
              <TableCell align="right">Additional Cost: </TableCell>
              <TableCell align="left">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={props.information.calculatedInformation.additionalCost}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">Total Cost: </TableCell>
              <TableCell align="left">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={props.information.calculatedInformation.totalCost}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">Loan: </TableCell>
              <TableCell align="left">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={props.information.calculatedInformation.loan}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
            </TableRow>
            <TableRow hover={false}>
              <TableCell align="right">Gross Return: </TableCell>
              <TableCell align="left">
                <NumericFormat
                  displayType="text"
                  decimalScale={2}
                  value={
                    props.information.calculatedInformation.grossReturn * 100
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" %"
                />
              </TableCell>
              <TableCell align="right">Return Factor: </TableCell>
              <TableCell align="left">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={props.information.calculatedInformation.returnFactor}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=""
                />
              </TableCell>
              <TableCell align="right">Annuity: </TableCell>
              <TableCell align="left">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={props.information.calculatedInformation.annuity}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
                <NumericFormat
                  displayType="text"
                  decimalScale={2}
                  value={
                    props.information.calculatedInformation.annuityRatio * 100
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix=" ("
                  suffix=" %)"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
