import { NumericFormat } from "react-number-format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Yearly } from "../../calculator/calculator";
import { Information } from "../information/InformationInput";

export type LoanEvaluationProps = {
  information: Information;
  yearly: Yearly[];
};

export function LoanEvaluation(props: LoanEvaluationProps) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Year</TableCell>
            <TableCell align="right">Rent</TableCell>
            <TableCell align="right">Interest</TableCell>
            <TableCell align="right">Repayment</TableCell>
            <TableCell align="right">Reserves</TableCell>
            <TableCell align="right">Cash Flow</TableCell>
            <TableCell align="right">Taxes</TableCell>
            <TableCell align="right">CF AT</TableCell>
            <TableCell align="right">Loan</TableCell>
            <TableCell align="right">Portfolio</TableCell>
            <TableCell align="right">Prop Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.yearly.map((entry) => (
            <TableRow hover={true} key={`loan-${entry.year}`}>
              <TableCell align="center">{entry.year}</TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.rent}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.interest}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.repayment}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={props.information.calculatedInformation.reserves}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.cashFlow}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.taxes}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.cashFlowAfterTaxes}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.rest}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.totalAssets}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.propertyValue}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
