import { calculate, Yearly } from "../../calculator/calculator";
import { NumericFormat } from "react-number-format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { fillNumbers } from "../../calculator/fill_numbers";
import { DefaultInformation } from "../information/DefaultInformation";
import { Information } from "../information/InformationInput";
import { LoanType } from "../information/LoanOptions";

function calculateAddLoan(amount: number): Yearly[] {
  const inf: Information = {
    ...DefaultInformation,
    financialInformation: {
      inflation: 0,
      loan: { creditTerm: 30, interestRate: 4, type: LoanType.Annuity },
      lossOfRentReserves: 0,
      maintenanceReserves: 0,
      rentIncreaseRate: 0,
    },
    objectInformation: {
      additionalCost: 0,
      brokerageFee: 0,
      buyingPrice: amount,
      landTransferTax: 0,
      notary: 0,
      sizeInSquareMeters: 0,
      valueGrowthThroughWork: 0,
      yearOfConstruction: 0,
      yearlyRent: 0,
    },
    personalInformation: { equity: 0, taxRate: 42 },
    stockInformation: { interestRateMax: 0, interestRateMin: 0 },
  };

  return calculate(fillNumbers(inf));
}

export function REInvestStrat() {
  let firstImmo: Information = {
    ...DefaultInformation,
    financialInformation: {
      ...DefaultInformation.financialInformation,
      inflation: 1.5,
    },
  };
  firstImmo = fillNumbers(firstImmo);
  const firstYearly = calculate(firstImmo);

  let secondImmo: Information = {
    ...DefaultInformation,
    financialInformation: {
      ...DefaultInformation.financialInformation,
      inflation: 1.5,
    },
  };
  secondImmo = fillNumbers(secondImmo);
  const secondYearly = calculate(secondImmo);

  const additionalLoan = calculateAddLoan(
    secondImmo.personalInformation.equity
  );
  console.log(additionalLoan);

  const data = [
    {
      year: "10.1",
      rent: firstYearly[10].rent,
      interest: firstYearly[10].interest,
      assets: firstYearly[10].totalAssets,
      propertyVals: firstYearly[10].propertyValue,
      cfAt: firstYearly[10].cashFlowAfterTaxes,
    },
    {
      year: "10.2",
      rent: firstYearly[11].rent + secondYearly[1].rent,
      interest:
        firstYearly[11].interest +
        secondYearly[1].interest +
        additionalLoan[1].interest,
      assets:
        firstYearly[11].totalAssets +
        secondYearly[1].totalAssets -
        additionalLoan[1].totalAssets,
      propertyVals:
        firstYearly[11].propertyValue + secondYearly[1].propertyValue,
      cfAt:
        firstYearly[11].cashFlowAfterTaxes +
        secondYearly[1].cashFlowAfterTaxes +
        additionalLoan[1].cashFlowAfterTaxes,
    },
    {
      year: "20",
      rent: firstYearly[20].rent + secondYearly[9].rent,
      interest:
        firstYearly[20].interest +
        secondYearly[9].interest +
        additionalLoan[9].interest,
      assets:
        firstYearly[20].totalAssets +
        secondYearly[9].totalAssets -
        additionalLoan[9].totalAssets,
      propertyVals:
        firstYearly[20].propertyValue + secondYearly[9].propertyValue,
      cfAt:
        firstYearly[20].cashFlowAfterTaxes +
        secondYearly[9].cashFlowAfterTaxes +
        additionalLoan[9].cashFlowAfterTaxes,
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Year</TableCell>
            <TableCell align="center">Rent</TableCell>
            <TableCell align="center">Interest</TableCell>
            <TableCell align="center">Assets</TableCell>
            <TableCell align="center">Property Vals</TableCell>
            <TableCell align="center">CF AT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((entry) => (
            <TableRow hover={true}>
              <TableCell align="center">{entry.year}</TableCell>
              <TableCell align="center">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.rent}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="center">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.interest}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="center">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.assets}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="center">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.propertyVals}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
              <TableCell align="center">
                <NumericFormat
                  displayType="text"
                  decimalScale={0}
                  value={entry.cfAt}
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" €"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
