import {NumericFormat, NumberFormatValues, SourceInfo} from "react-number-format"
import { TextField } from '@mui/material';

export type FormatValues = NumberFormatValues;
export type OnValueChange = (values: FormatValues) => void;

export type NumberProps = {
	label: string,
	value: number,
	onChange: OnValueChange,
	allowNegative?: boolean,
	prefix?: string;
	decimalScale?: number;
}

export function Number(props: NumberProps) {
	const onChange = (values: NumberFormatValues, _si: SourceInfo) => {
		props.onChange(values);
	}
	return (
		<NumericFormat 
			value={props.value}
			displayType="input"
			onValueChange={onChange}
			customInput={TextField}
			variant="standard"
			prefix={props.prefix}
			decimalScale={props.decimalScale ?? 2}
			allowNegative={props.allowNegative ?? false}
			decimalSeparator=","
			thousandSeparator="."
			label={props.label} />
	)
}
