import { Information } from "../components/information/InformationInput";
import { getAnnuity } from "./annuity_calculator";

function getObjectAge(buildIn: number): number {
  return new Date().getFullYear() - buildIn;
}

export function fillNumbers(inf: Information): Information {
  const addCost =
    (inf.objectInformation.buyingPrice / 100) *
    (inf.objectInformation.brokerageFee +
      inf.objectInformation.landTransferTax +
      inf.objectInformation.notary);

  const totalCost =
    inf.objectInformation.buyingPrice +
    inf.objectInformation.additionalCost +
    addCost;

  const loan = totalCost - inf.personalInformation.equity;

  // https://www.drklein.de/instandhaltungsruecklage.html
  // TODO: way too complext. Maybe have a look later again
  // const maintenanceReserves =
  // (inf.objectInformation.buyingPrice *
  // inf.financialInformation.reservesPerSquerMeter) /
  // (80 - getObjectAge(inf.objectInformation.yearOfConstruction));

  const maintenanceReserves =
    inf.objectInformation.sizeInSquareMeters *
    inf.financialInformation.maintenanceReserves *
    12;

  const lossOfRentReserves =
    (inf.objectInformation.yearlyRent / 100) *
    inf.financialInformation.lossOfRentReserves;
  const reserves = lossOfRentReserves + maintenanceReserves;

  const annuity = getAnnuity(
    loan,
    inf.financialInformation.loan.interestRate / 100,
    inf.financialInformation.loan.creditTerm
  );
  const annuityRatio = annuity / loan;
  const propertyValue =
    inf.objectInformation.buyingPrice +
    inf.objectInformation.valueGrowthThroughWork;

  return {
    ...inf,
    calculatedInformation: {
      totalCost,
      loan,
      reserves,
      additionalCost: addCost,
      equityRatio:
        (inf.personalInformation.equity / inf.objectInformation.buyingPrice) *
        100,
      annuity,
      annuityRatio,
      initialPropertyValue: propertyValue,

      grossReturn:
        inf.objectInformation.yearlyRent / inf.objectInformation.buyingPrice,
      returnFactor:
        inf.objectInformation.buyingPrice / inf.objectInformation.yearlyRent,
    },
  };
}
