import { Information } from "../components/information/InformationInput";
import { Yearly } from "./calculator";

const stockTaxRate = 26.375;

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.random() * (max - min) + min;
}

export function calculateStock(inf: Information, yearly: Yearly[]) {
  let portfolio = inf.personalInformation.equity;
  let totalInvest = inf.personalInformation.equity;
  for (const i in yearly) {
    const year = yearly[i];
    if (i === "0") {
      year.stockPortfolio = portfolio;
      year.stockInterest = 0;
      year.stockSaving = 0;
      year.stockTotalInvest = 0;
      year.stockPortfolioAfterTaxes = portfolio;
      continue;
    }
    const interestRate = getRandomInt(
      inf.stockInformation.interestRateMin,
      inf.stockInformation.interestRateMax
    );
    const interest = (portfolio / 100) * interestRate;

    let saving = 0;
    portfolio += interest;
    if (year.cashFlowAfterTaxes < 0) {
      saving = -year.cashFlowAfterTaxes;
      totalInvest += saving;
    } else {
      // If we have to sell stock, we need to calculate the taxes
      saving = (year.cashFlowAfterTaxes / (100 - stockTaxRate)) * 100 * -1;
    }

    portfolio += saving;

    year.stockSaving = saving;
    year.stockInterest = interest;
    year.stockInterestRate = interestRate;
    year.stockPortfolio = portfolio;
    year.stockTotalInvest = totalInvest;
    year.stockPortfolioAfterTaxes =
      portfolio - (portfolio - totalInvest) * (stockTaxRate / 100); // To calculate the portfolio after taxes, we need to remove the taxes on the profits
  }
}
