import numeral from "numeral";

export function formatEuro(value: any, withDecimal: boolean): string {
  if (withDecimal) {
    return `${numeral(value).format("0,0.00")} €`;
  }
  return `${numeral(value).format("0,0")} €`;
}

export function formatPercent(value: any, withDecimal: boolean): string {
  if (withDecimal) {
    return `${numeral(value).format("0.00")} %`;
  }
  return `${numeral(value).format("0")} %`;
}
