// https://www.infina.at/ratgeber/finanzierung/annuitaetendarlehen/#c25540
// https://www.haushaltsfinanzen.de/finanzmathematik/annuitaeten.php?Annuitaetendarlehen-mit-online-Tilgungsplan-Rechner
// https://www.bambus.io/content/was-ist-ein-annuitaetendarlehen-und-wie-berechnet-sich-dieses
export function getRepaymentAtYear(
  year: number,
  loan: number,
  interestRate: number,
  creditTerm: number
): number {
  return (
    ((interestRate * (1 + interestRate) ** (year - 1)) /
      ((1 + interestRate) ** creditTerm - 1)) *
    loan
  );
}

export function getInterestAtYear(
  year: number,
  loan: number,
  interestRate: number,
  creditTerm: number
) {
  return (
    ((interestRate *
      ((1 + interestRate) ** creditTerm - (1 + interestRate) ** (year - 1))) /
      ((1 + interestRate) ** creditTerm - 1)) *
    loan
  );
}

export function getAnnuity(
  loan: number,
  interestRate: number,
  creditTerm: number
): number {
  return (
    getRepaymentAtYear(1, loan, interestRate, creditTerm) +
    getInterestAtYear(1, loan, interestRate, creditTerm)
  );
}
