import { Information } from "./components/information/InformationInput";

const LocalStorageKey = "information";

export function load(): Information | null {
  const data = localStorage.getItem(LocalStorageKey);
  if (data == null) {
    return null;
  }
  return JSON.parse(data);
}

export function persist(inf: Information) {
  localStorage.setItem(LocalStorageKey, JSON.stringify(inf));
}
