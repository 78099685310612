import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { Yearly } from "../../calculator/calculator";
import { Information } from "../information/InformationInput";
import { formatEuro, formatPercent } from "../../utils/formatter";
import { Grid } from "@mui/material";

export type GeneralComparisonChartsProps = {
  information: Information;
  yearly: Yearly[];
};

export function GeneralComparisonCharts(props: GeneralComparisonChartsProps) {
  const data = props.yearly.map((entry) => ({
    name: `Year ${entry.year}`,
    re: entry.totalAssets,
    stock: entry.stockPortfolio || 0,
    stockAT: entry.stockPortfolioAfterTaxes || 0,
    loan: entry.rest,
  }));
  const interestComparison = props.yearly.map((entry) => ({
    name: `Year ${entry.year}`,
    reInterest: entry.investmentInterest,
    reInterestRate: entry.investmentInterestRate,
    stockInterest: entry.stockInterest,
    stockInterestRate: entry.stockInterestRate,
  }));
  return (
    <Grid container>
      <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            syncId="generalComparisonCharts"
            margin={{
              top: 5,
              right: 100,
              left: 100,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              tickFormatter={(val) => formatEuro(val, false)}
              width={100}
            />
            <Tooltip formatter={(val) => formatEuro(val, true)} />
            <Legend />
            <ReferenceLine
              x="Year 10"
              stroke="green"
              strokeDasharray="3 3"
              label="10 Years"
            />
            <ReferenceLine
              x="Year 20"
              stroke="green"
              strokeDasharray="3 3"
              label="20 Years"
            />
            <ReferenceLine x="Year 40" stroke="green" strokeDasharray="3 3" />
            <Line
              type="monotone"
              dataKey="re"
              name="Real Estate"
              stroke="#8884d8"
            />
            <Line
              type="monotone"
              dataKey="stock"
              name="Stock"
              stroke="#eb8f34"
            />
            <Line
              type="monotone"
              dataKey="stockAT"
              name="Stock AT"
              stroke="#82ca9d"
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={6}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={interestComparison}
            syncId="generalComparisonCharts"
            margin={{
              top: 5,
              left: 100,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              yAxisId="interest"
              tickFormatter={(val) => formatEuro(val, false)}
              width={100}
            />
            <Tooltip formatter={(val) => formatEuro(val, true)} />
            <Legend />
            <ReferenceLine
              x="Year 10"
              stroke="green"
              strokeDasharray="3 3"
              label="10 Years"
              yAxisId="interest"
            />
            <ReferenceLine
              x="Year 20"
              stroke="green"
              strokeDasharray="3 3"
              label="20 Years"
              yAxisId="interest"
            />
            <ReferenceLine
              x="Year 40"
              stroke="green"
              strokeDasharray="3 3"
              yAxisId="interest"
            />
            <Line
              type="monotone"
              dataKey="reInterest"
              name="Real Estate Interest"
              stroke="#8884d8"
              yAxisId="interest"
            />
            <Line
              type="monotone"
              dataKey="stockInterest"
              name="Stock Interest"
              stroke="#eb8f34"
              yAxisId="interest"
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={6}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={interestComparison}
            syncId="generalComparisonCharts"
            margin={{
              top: 5,
              right: 100,
              left: 50,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(val) => formatPercent(val, false)} />
            <Tooltip formatter={(val) => formatPercent(val, true)} />
            <Legend />
            <ReferenceLine
              x="Year 10"
              stroke="green"
              strokeDasharray="3 3"
              label="10 Years"
            />
            <ReferenceLine
              x="Year 20"
              stroke="green"
              strokeDasharray="3 3"
              label="20 Years"
            />
            <ReferenceLine x="Year 40" stroke="green" strokeDasharray="3 3" />
            <Line
              type="monotone"
              dataKey="reInterestRate"
              name="RE Interest Rate"
              stroke="#8884d8"
            />
            <Line
              type="monotone"
              dataKey="stockInterestRate"
              name="Stock Interest Rate"
              stroke="#82ca9d"
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
}
